import 'assets/css/normalize.css';
import 'assets/css/tailwind.css';
import React, {Suspense} from 'react';
import {Router, Switch, Route} from "react-router-dom";
import history from './app/shared/history';
import Redirect from "react-router-dom/es/Redirect";

const WebsiteComponent = React.lazy(() => import('./website/website'));
const LoginUserComponent = React.lazy(() => import('./app/login/user/user'));
const LoginCompanyComponent = React.lazy(() => import('./app/login/company/company'));
const ImportQuizComponent = React.lazy(() => import('./app/quizzes/review/review_import'));
const ReviewQuizComponent = React.lazy(() => import('./app/quizzes/review/review_quiz'));
const LoginCallbackComponent = React.lazy(() => import('./app/login/callback/callback'));
const CompanyComponent = React.lazy(() => import('./app/company/shared/components/company'));
const SuperadminComponent = React.lazy(() => import('./app/superadmin/superadmin'));
const UserComponent = React.lazy(() => import('./app/user/user'));
const InviteComponent = React.lazy(() => import('./app/invite/invite'));
const AdaptiveQuizComponent = React.lazy(() => import('./app/quizzes/adaptive/adaptive'));
const UnusedImportsComponent = React.lazy(() => import('./app/shared/components/UnusedImports'));
const PrivacyPolicyComponent = React.lazy(() => import('./legal/privacy-policy'));
const TermsAndConditionsComponent = React.lazy(() => import('./legal/terms-and-conditions'));


const App = () => {
    return (
        <Router history={history}>
            <Suspense fallback={<div />}>
                <Switch>

                    <Route exact path={["/"]} component={() => <WebsiteComponent />} />

                    <Route exact path={["/login/user", "/login"]} component={() => <LoginUserComponent />} />
                    <Route exact path={["/login/company", "/login_company"]} component={() => <LoginCompanyComponent />} />
                    <Route exact path={["/login/callback/google", "/login/callback/facebook"]} component={() => <LoginCallbackComponent />} />

                    <Route exact path={["/quiz/adaptive/:invite_uid"]} component={AdaptiveQuizComponent} />

                    <Route exact path={["/quiz/review/import"]} component={() => <ImportQuizComponent />} />
                    <Route exact path={["/quiz/review/:quiz_uid"]} component={ReviewQuizComponent} />

                    <Route path={["/company"]} component={CompanyComponent} />

                    <Route path={["/superadmin"]} component={SuperadminComponent} />

                    <Route path={["/me"]} component={UserComponent} />

                    <Route path={["/invite"]} component={InviteComponent} />

                    <Route path={["/unused"]} component={UnusedImportsComponent} />

                    {/*legal*/}
                    <Route path={["/privacy-policy-v2"]} component={PrivacyPolicyComponent} />
                    <Route path={["/terms-of-service-v2"]} component={TermsAndConditionsComponent} />
                    <Route exact path="/terms-of-service"><Redirect to="/" /></Route>
                    <Route exact path="/privacy-policy"><Redirect to="/" /></Route>
                    {/*legal*/}

                </Switch>
            </Suspense>
        </Router>
    );
};

export default App;
